import React, {useCallback, useEffect, useState} from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {useRouter} from "next/router";
import messages from '../../../../src/messages';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
export default function DialogAlert ({alertType, isMessageReady, message,toggleAlert, showAndLoadLayers, changeTabWithNewLoad, doRedirect,typeWindow,finishUpdateTexturesOnProduct,
                                         applySubscriptionState,applySubscription,cancelDeleteGroups,
                                         triggerDeleteGroups,
                                         setApplySubscriptionState}) {

    const [alertShow, setAlertShow] = useState(false);
    const [alertTransitionShow, setAlertTransitionShow] = useState(false);
    const router = useRouter();
    const changeAlertShow = (variable) => {

        if(variable === true){

            setAlertShow(variable);
            setTimeout(function() {
                setAlertTransitionShow(true);
            },1);




        } else{


            setAlertTransitionShow(false);
            setTimeout(function() {
                setAlertShow(variable);
            },500);



        }

    };

    const toggleAlertInside = useCallback((): void => {
console.log("ALERT INSIDE",alertShow);
        changeAlertShow(true);
        if(alertShow){
            changeAlertShow(false);
        }else{
            changeAlertShow(true);
        }


    }, []);
    useEffect(() => {

        toggleAlert && toggleAlert(toggleAlertInside);
    }, [toggleAlert, toggleAlertInside]);


    const closeModalOutsideClick = (e) => {

        if( e.target.getAttribute("data-closemodal") === "true"){
            changeAlertShow(false);
        }
    }
    const goToHomepage = () => {

     router.push("/");
    }


    const closeAndUpdateLayers = () => {
        changeAlertShow(false);
        finishUpdateTexturesOnProduct();
    }
    const closeAndShowLayers = () => {
        changeAlertShow(false);
        showAndLoadLayers();
    }

    const closeAndLoadTab = () => {
        changeAlertShow(false);
        changeTabWithNewLoad();
    }




    const applySubscriptionInner = () => {
        changeAlertShow(false);
        applySubscription();
    }

    const closeConfirmSubscription = () => {
        changeAlertShow(false);
       // setApplySubscriptionState(null);
    }

    const cancelDeleteGroupsInner = () => {
        changeAlertShow(false);
        cancelDeleteGroups();
        // setApplySubscriptionState(null);
    }

    const intl = useIntl();
    const [messageMain, setMessageMain] = useState(null);


    useEffect(() => {
        console.log("SETTING THIS ", message, isMessageReady);
                if(message &&!isMessageReady){
                    setMessageMain( intl.formatMessage({ id: message }));
                } else {
                    setMessageMain(message);

                }
    }, [message]);

    return (
        <>
            <ModalsWrapper className={`alertCheck  ${alertTransitionShow ? 'showTransition' : 'hideTransition'}
             ${!alertShow ? 'hiddeComplete' : ''}`}
                           data-closemodal={true}
                           onClick={(e)=> closeModalOutsideClick(e)}
            >


                <ModalWindow
                    className={"center alert"}
                    id={'alertProductDialog'}
                style={{zIndex:"9999999999999999999"}}>
                    {alertType !== 'hlaska' ?
                        <div className={'modal-header'}>

                            {alertType === 'information' ?

                                <h4><FormattedMessage {...messages.modal_alert_title_information}></FormattedMessage>
                                </h4>
                                : null}
                            {alertType === 'warning' ?

                                <h4 className={'warning'}>
                                    <FormattedMessage {...messages.modal_alert_title_warning}></FormattedMessage></h4>
                                : null}
                            {alertType === 'global-error.tsx' || alertType === 'error' ?

                                <h4 className={'error'}>
                                    <FormattedMessage {...messages.modal_alert_title_error}></FormattedMessage></h4>
                                : null}

                            { alertType === 'groupsConfirm' ?

                                <h4 className={'warning'}>
                                    <FormattedMessage {...messages.modal_alert_title_warning}></FormattedMessage></h4>
                                : null}


                            {typeWindow === 'applySubscription' ?
                                <h4 className={'information'}>
                                    <FormattedMessage {...messages.modal_alert_title_subscription_update}></FormattedMessage>
                                </h4>
                                : null}
                            {typeWindow === 'successUpgrade' || typeWindow === 'successDowngrade' ?
                                <h4 className={'information'}>
                                    <FormattedMessage {...messages.modal_alert_title_subscription_success}></FormattedMessage>
                                </h4>
                                : null}


                        </div> : null
                    }


                    <div className={'modal-content shareProduct'} style={{ display: 'block' }}>

                        {typeWindow !== 'successUpgrade' && typeWindow !== 'successDowngrade' ?
                            <span style={typeWindow ? {
                                width: '100%',
                                fontSize: '15px',
                                color: 'var(--black-60, #666)',
                            } : { paddingBottom: '15px',width:"100%",marginTop:"15px",marginBottom:"15px" }}>
                            {messageMain ? messageMain : null}
                             {/*   {message?
                                    <FormattedMessage id={message}></FormattedMessage>: null }*/}

                                </span>
                            : null}


                        {typeWindow === 'layers' ?
                            <div className={'modal-buttons'} style={{ width: '100%', height: 'auto' }}>


                                <GlobalButtonFinal style={{ width: 'fit-content' }} className={'borderBlue btn-circle'}
                                                   onClick={() => closeAndUpdateLayers()}>
                                    <FormattedMessage {...messages.modal_alert_buttons_layers_yes}></FormattedMessage>
                                </GlobalButtonFinal>

                                <GlobalButtonFinal style={{ width: 'fit-content' }} className={'borderBlue btn-circle'}
                                                   onClick={() => closeAndShowLayers()}>
                                    <FormattedMessage {...messages.modal_alert_buttons_layers_no}></FormattedMessage>
                                </GlobalButtonFinal>

                            </div>
                            : null}

                        {typeWindow === "switchTabs" ?
                            <div className={"modal-buttons space"}  style={{    width: "100%", height:"auto"}}>

                                <GlobalButtonFinal style={{ width: "fit-content" }} className={"borderBlue btn-circle"}
                                                   onClick={ () => changeAlertShow(false)}>
                                    <FormattedMessage {...messages.modal_alert_buttons_no}></FormattedMessage>
                                </GlobalButtonFinal>

                                <GlobalButtonFinal style={{ width: "fit-content" , marginLeft:"10px!important"}} className={"borderBlue btn-circle"}
                                                   onClick={()=> closeAndLoadTab()}>
                                    <FormattedMessage {...messages.modal_alert_buttons_yes}></FormattedMessage>
                                </GlobalButtonFinal>


                            </div>
                            : null}

                        {typeWindow === "load" ?
                            <GlobalButtonFinal style={{ width: "fit-content"  ,marginTop:"10px"}} className={"borderBlue btn-circle"}
                                               onClick={doRedirect ? () => goToHomepage() : () => changeAlertShow(false)}>
                                <FormattedMessage {...messages.modal_alert_buttons_ok}></FormattedMessage>
                            </GlobalButtonFinal>
                            : null}

                        {!typeWindow && alertType !== 'groupsConfirm'?
                            <GlobalButtonFinal style={{ width: "fit-content" }} className={"borderBlue btn-circle"}
                                               onClick={doRedirect ? () => goToHomepage() : () => changeAlertShow(false)}>
                                <FormattedMessage {...messages.modal_alert_buttons_ok}></FormattedMessage>
                            </GlobalButtonFinal>
                            : null}


                        {typeWindow === "applySubscription" ?
                            <div className={"modal-buttons"}>
                                <GlobalButtonFinal style={{ width: "fit-content" }} className={"borderBlue btn-circle"}
                                                   onClick={() => applySubscriptionInner()}>
                                    <FormattedMessage {...messages.modal_alert_buttons_ok}></FormattedMessage>
                                </GlobalButtonFinal>

                                <GlobalButtonFinal style={{ width: "fit-content",marginLeft:"10px" }} className={"borderBlue btn-circle"}
                                                   onClick={() => closeConfirmSubscription()}>
                                    <FormattedMessage {...messages.modal_alert_buttons_cancel}></FormattedMessage>
                                </GlobalButtonFinal>

                            </div>
                            : null}
                        {typeWindow === "successUpgrade" || typeWindow === "successDowngrade" ?
                            <div className={"modal-buttons"}>
                                <GlobalButtonFinal style={{ width: "fit-content" }} className={"borderBlue btn-circle"}
                                                   onClick={() => closeConfirmSubscription()}>
                                    <FormattedMessage {...messages.modal_alert_buttons_ok}></FormattedMessage>
                                </GlobalButtonFinal>
                            </div>
                            : null}

                        {typeWindow === "productLimit" ?
                            <div className={"modal-buttons"}>
                                <GlobalButtonFinal style={{ width: "fit-content" }} className={"borderBlue btn-circle"}
                                                   onClick={() => window.open("/subscription","_self")}>
                                    <FormattedMessage {...messages.modal_alert_buttons_ok}></FormattedMessage>
                                </GlobalButtonFinal>
                            </div>
                            : null}

                        {alertType === 'groupsConfirm' ?

                            <div className={"modal-buttons"}>
                                <GlobalButtonFinal style={{ width: "fit-content" }} className={"borderBlue btn-circle"}
                                                   onClick={() => cancelDeleteGroupsInner()}>
                                    <FormattedMessage {...messages.modal_alert_buttons_no}></FormattedMessage>
                                </GlobalButtonFinal>
                                <GlobalButtonFinal style={{ width: "fit-content",marginLeft:"15px" }} className={"borderBlue btn-circle"}
                                                   onClick={() => triggerDeleteGroups()}>
                                    <FormattedMessage {...messages.modal_alert_buttons_yes}></FormattedMessage>
                                </GlobalButtonFinal>

                            </div>
                            : null}


                    </div>
                </ModalWindow>


            </ModalsWrapper>


        </>
    )
}